<template>
  <smart-popup
    v-if="linkTarget"
    :value="value"
    :preserve-elements="elementsToPreserve"
    v-on="$listeners"
  >
    <template v-slot:title>
      <slot name="title">
        <v-chip>
          <document-picker-list-item-ref
            :item="linkTarget"
            :show-icon="true"
            class="mt-1"
          />
        </v-chip>
      </slot>
    </template>
    <search
      lazy
      :link-target="linkTarget"
      :scope="scope"
      @hook:mounted="setupSearch"
      ref="search"
    />
  </smart-popup>
</template>

<script>

import Scope from './controllers/scope'
import { GlobalSearch } from './controllers'

export default {
  components: {
    DocumentPickerListItemRef: () => import('@/components/document-picker-list-item-ref'),
    Search: () => import('./components/search'),
    SmartPopup: () => import('@/components/smart-popup')
  },
  computed: {
    scope () {
      return Scope.global()
    }
  },
  data () {
    return {
      elementsToPreserve: () => [
        { element: document.getElementById('main-presets') },
        { element: document.getElementById('search-field') },
        { element: document.getElementById('side-panel'), pushContent: true },
        { element: document.getElementById('side-panel-open-button'), isCircle: true }
      ]
    }
  },
  methods: {
    setupSearch () {
      const allowedDocumentTypes = this.linkTarget.type !== 'topics'
        ? ['topics']
        : ['accounts', 'collaboration-tasks', 'disputes', 'groupama-requests', 'invoices', 'messages-inbox', 'messages-outbox', 'promises', 'topics']
      this.$refs.search?.search?.chain(s => s.searchedIds.clear().exclude([this.linkTarget]))
        .searchedDocumentTypes
        .clear()
        .include(allowedDocumentTypes)
      if (allowedDocumentTypes.length === 1) {
        this.$refs.search?.search.setDocumentType(allowedDocumentTypes[0])
      }
    }
  },
  watch: {
    value (value) {
      if (value) {
        if (this.$refs?.search?.search) {
          this.$refs.search.search.refreshResults()
          GlobalSearch.register(this.$refs.search.search, [GlobalSearch.GLOBAL_MODE, GlobalSearch.LOCAL_MODE])
          GlobalSearch.allowNavigation = false
        }
      } else {
        if (this.$refs?.search?.search) {
          GlobalSearch.unregister(this.$refs.search.search)
          GlobalSearch.allowNavigation = true
        }
        this.$emit('update-links')
      }
    }
  },
  props: {
    value: Boolean,
    linkTarget: Object
  }
}
</script>
